<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ $t('Navigation.Products/' + Action) }}</h5>
            <div class="card-header-actions d-flex align-items-center">
              <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTabs fade addTabsWrapperClasses="tab-info">
              <CTab :title="$t('Product/Detail.Info')" active>
                <CRow>
                  <CCol md="8">
                    <CInput
                        v-if="Action === 'EditPlan'"
                        :label="$t('Product/Plan.SeriesNum')"
                        v-model="Data.SeriesNum"
                        horizontal
                        disabled
                    />
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Product/Plan.Name')"
                        v-model="Data.Name"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Plan.OtherName')"
                        v-model="Data.OtherName"
                        horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            v-model="Data.Tags"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.Tags"
                            :multiple="true"
                            :taggable="true"
                            @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Features') }}
                        </CCol>
                        <CCol sm="9">
                          <CInput v-for="(Item, Index) in Data.Variable.FeatureText" type="text" :key="'FeatureText-' + Index" v-model="Item.Value">
                            <template #append>
                              <CButton color="light" @click="RemoveVariableArrayInput('FeatureText', Index)">
                                <CIcon class="my-0" name="cil-trash" size="sm"/>
                              </CButton>
                            </template>
                          </CInput>
                          <CButton color="info" size="sm" type="button" @click="AddVariableArrayInput('FeatureText')">
                            <CIcon name="cil-plus" class="mr-1" /> {{ $t('Global.Add') }}
                          </CButton>
                        </CCol>
                      </CRow>
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Product/Plan.Name')"
                        v-model="Data.LanguageData[NowChooseLanguage].Name"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Plan.OtherName')"
                        v-model="Data.LanguageData[NowChooseLanguage].OtherName"
                        horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            id="Tags"
                            :key="NowChooseLanguage"
                            v-model="Data.LanguageData[NowChooseLanguage].Tags"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.LanguageData[NowChooseLanguage].Tags"
                            :multiple="true"
                            :taggable="true"
                            @remove="RemoveLocaleTag"
                            @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Features') }}
                        </CCol>
                        <CCol sm="9">
                          <CInput v-for="(Item, Index) in Data.LanguageData[NowChooseLanguage]['Variable-FeatureText']" type="text" :key="'FeatureText-' + Index" v-model="Item.Value">
                            <template #append>
                              <CButton color="light" @click="RemoveVariableArrayInput('FeatureText', Index, NowChooseLanguage)">
                                <CIcon class="my-0" name="cil-trash" size="sm"/>
                              </CButton>
                            </template>
                          </CInput>
                          <CButton color="info" size="sm" type="button" @click="AddVariableArrayInput('FeatureText', NowChooseLanguage)">
                            <CIcon name="cil-plus" class="mr-1" /> {{ $t('Global.Add') }}
                          </CButton>
                        </CCol>
                      </CRow>
                    </template>
                  </CCol>
                  <CCol md="4">
                    <CInput
                        :label="$t('Product/Detail.Sort')"
                        v-model="Data.Sort"
                        type="number"
                        horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Plan.Status') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="ProductStatus"/>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.Amount')">
                <CRow>
                  <CCol md="8">
                    <CInput
                      :prepend="$store.state.currency + '$'"
                      :label="$t('Product/Plan.Amount/Suggest')"
                      v-model="Data.Amount.Suggest"
                      type="number"
                      :disabled="EnableOption"
                      horizontal />
                    <CInput
                        :prepend="$store.state.currency + '$'"
                        :label="$t('Product/Plan.Amount/Actual')"
                        v-model="Data.Amount.Actual"
                        type="number"
                        :disabled="EnableOption"
                        horizontal />
                    <CInput
                      :append="$t('Global.TimeUnit.Month')"
                      :label="$t('Product/Plan.PlanCycle')"
                      v-model="Data.Amount.Member"
                      type="number"
                      :disabled="EnableOption"
                      horizontal />
                    <CInput
                      :append="$t('Global.Times')"
                      :label="$t('Product/Plan.ExecuteTimes')"
                      v-model="Data.Amount.Cost"
                      type="number"
                      :disabled="EnableOption"
                      description="因綠界/藍新金流要求必需設定委託單總扣款次數，未填寫則預設扣款次數為6次。若使用TapPay付款則此欄位將忽略。"
                      horizontal />
                  </CCol>
                  <CCol md="4">
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Plan.Images')">
                <h5>{{ $t('Product/Detail.Cover') }}</h5>
                <hr>
                <CRow class="no-gutters mx-n1 mb-3">
                  <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                    <div class="position-relative CarouselItem">
                      <img v-lazy="Data.Cover" class="img-fluid" />
                      <div class="CarouselTools">
                        <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                          <CIcon name="cil-pencil" />
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                    <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                      <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                      <div class="CarouselTools">
                        <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                          <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Plan.Content')">
                <Editor api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="CurrentContent" />
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton v-if="Action === 'EditPlan'" color="info" type="button" class="mr-2" @click="$router.push('/products/add')">
              {{ $t('Global.ContinueAdd') }}
            </CButton>
            <CButton color="success" type="submit">
              {{ Action === 'EditPlan' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta"></MediaMeta>
    <input id="tinymce-image-input" type="text" class="d-none">
  </section>
</template>

<route>
{
  "meta": {
    "label": "編輯方案"
  }
}
</route>

<script>
import dayjs from 'dayjs'
import DataSchema from '@/schema/product/detail'
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'ProductDetailPlan',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    MediaStore: () => import('@/components/public/Media'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Editor
  },
  data () {
    return {
      Action: 'EditPlan',
      Data: {}, //主資料

      ProductStatus: false, //格式化後的商品狀態
      EnableOption: false, //格式化後的商品選項開關
      EnableStock: false, //格式化後的商品庫存啟用開關
      EnableMemberAmount: false, //格式化後的會員價啟用開關
      HiddenAmount: false, //格式化後的隱藏價格開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      Debug: false,
      VariableList: [],
      NowChooseDevice: 'Web',
      NowChooseLanguage: 'default',
      Loading: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false,
        ChooseImageType: '',
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },
      VariableExtend: {},
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },

      TypeList: ['boolean', 'string', 'number', 'object', 'array'],
      VariableNoItems: {
        noResults: this.$t('Product/Detail.NoVariableItems'),
        noItems: this.$t('Product/Detail.NoVariableItems')
      }
    }
  },
  computed: {
    CurrentContent: {
      get () {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    return this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content
                  }
              }
            }
            return this.Data.LanguageData[this.NowChooseLanguage].Content
          }
        }
        if (this.EnableResponseContent) {
          switch (this.NowChooseDevice) {
            case 'Web':
            case 'Mobile':
              if (this.Data.ResponseData[this.NowChooseDevice]) {
                return this.Data.ResponseData[this.NowChooseDevice].Content
              }
          }
        }
        return this.Data.Content
      },
      set (value) {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content = value
                  }
              }
            } else {
              this.Data.LanguageData[this.NowChooseLanguage].Content = value
            }
          }
        } else {
          if (this.EnableResponseContent) {
            switch (this.NowChooseDevice) {
              case 'Web':
              case 'Mobile':
                if (this.Data.ResponseData[this.NowChooseDevice]) {
                  this.Data.ResponseData[this.NowChooseDevice].Content = value
                }
            }
          } else {
            this.Data.Content = value
          }
        }
      }
    }
  },
  created() {
    this.$set(this.$data, 'Data', Object.assign({}, DataSchema))
    this.Action = (this.$route.name === 'products-detail-plan-add' ? 'AddPlan' : 'EditPlan')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      this.EnableMemberAmount = JSON.parse(localStorage.getItem('Permission')).Permission.Organization.Member.includes('W')
      const Functions = [
          this.GetExtendData()
      ]
      if (this.Action === 'EditPlan') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        //詳細資料處理
        this.HandleExtendData()
        if (this.Action === 'EditPlan') this.HandleDetailData()
        this.InitLocaleData()
        return true
      }).catch((err) => {
        console.error(err)
        throw err
      })
    },
    Save() {
      //拆分動作
      let Action
      switch (this.Action) {
        case 'EditPlan':
          Action = this.Edit()
          break
        case 'AddPlan':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Product/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'AddPlan') {
          this.$router.push('/products/plan/detail/' + result.data.SeriesNum)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Product/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Product/SlugEmpty')
        }
      }
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/add',
        method: 'post',
        data: {
          ...this.Data,
          Taxonomy: 'Plan',
          Status: this.ProductStatus ? 1 : 0,
          EnableResponseContent: this.EnableResponseContent ? 1 : 0,
          OnlineTime: dayjs(this.Data.OnlineTime).unix(),
          OfflineTime: dayjs(this.Data.OfflineTime).unix(),
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Product/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Product/SlugEmpty')
        }
      }
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      const updateData = {
        ...this.Data,
        Status: this.ProductStatus ? 1 : 0,
        EnableResponseContent: this.EnableResponseContent ? 1 : 0,
        OnlineTime: dayjs(this.Data.OnlineTime).unix(),
        OfflineTime: dayjs(this.Data.OfflineTime).unix()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/edit',
        method: 'post',
        data: {
          seriesNum: updateData.SeriesNum,
          taxonomy: 'Plan',
          updateData
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Product/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/detail',
        method: 'get',
        params: {
          seriesNum: this.$route.params.id,
          taxonomy: 'Plan'
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
        if (!this.Data.Variable) this.$set(this.Data, 'Variable', {})
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetExtendData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/extend',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.VariableExtend = data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    HandleExtendData() {
      Object.keys(this.VariableExtend).forEach((Variable) => {
        if (!this.Data.Variable[Variable]) this.$set(this.Data.Variable, Variable, '')
      })
    },
    HandleDetailData() {
      if (this.Data.Variable) {
        this.VariableList = Object.keys(this.Data.Variable).map((data) => {
          let Value,
              Type = typeof this.Data.Variable[data]
          switch (typeof this.Data.Variable[data]) {
            case 'boolean':
            case 'string':
            case 'undefined':
            case 'number':
              Value = this.Data.Variable[data]
              break
            case 'object':
              Value = JSON.stringify(this.Data.Variable[data])
              if (Array.isArray(this.Data.Variable[data])) {
                Type = 'array'
              }
              break
          }
          return {
            Index: data,
            Value,
            Type
          }
        })
      }

      this.ProductStatus = (this.Data.Status === 1)
      this.EnableOption = (this.Data.EnableOption === 1)
      this.EnableStock = (this.Data.EnableStock === 1)
      this.EnableMemberAmount = (this.Data.EnableMemberAmount === 1)
      this.HiddenAmount = (this.Data.HiddenAmount === 1)
      this.EnableResponseContent = (this.Data.EnableResponseContent === 1)
    },
    HandleVariable() {
      this.VariableList.forEach((data) => {
        this.Data.Variable[data.Index] = data.Value
      })
    },
    AddTag (newTag) {
      this.Data.Tags.push(newTag)
      if (this.NowChooseLanguage !== 'default') {
        this.Data.LanguageData[this.NowChooseLanguage].Tags.push(newTag)
      }
    },
    RemoveLocaleTag (removedOption) {
      this.Data.Tags.splice(this.Data.Tags.indexOf(removedOption), 1)
    },
    AddVariable() {
      this.VariableList.push({
        Index: '',
        Value: '',
        Type: 'string'
      })
      this.HandleVariable()
    },
    RemoveVariable(index) {
      this.VariableList.splice(index, 1)
      this.HandleVariable()
    },
    AddVariableArrayInput(variable, locale) {
      if (locale) {
        if (!this.Data.LanguageData[locale][`Variable-${variable}`]) {
          this.$set(this.Data.LanguageData[locale], `Variable-${variable}`, [])
        }
        this.Data.LanguageData[locale][`Variable-${variable}`].push({
          Value: ''
        })
      } else {
        if (!this.Data.Variable[variable]) {
          this.$set(this.Data.Variable, variable, [])
        }
        this.Data.Variable[variable].push({
          Value: ''
        })
      }
    },
    RemoveVariableArrayInput(variable, index, locale) {
      if (locale) {
        this.Data.LanguageData[locale][`Variable-${variable}`].splice(index, 1)
      } else {
        this.Data.Variable[variable].splice(index, 1)
      }
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = this.MediaStoreConfig.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages (type = 'Carousel') {
      const storageInstance = this.$store.state.userProjectApp.storage()
      switch (type) {
        case 'Cover':
          this.SetSingleImage('Cover')
          break
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'));
          break
        default:
          if (typeof this.Data[type] === 'undefined') {
            this.$set(this.Data, type, [])
          }
          this.ChooseImageType = ''
            switch (typeof this.MediaStoreConfig.ChooseImageList) {
              case 'string':
                if (this.$store.state.user.permission.StaticDomain) {
                  this.$set(this.Data[type], this.ChooseImageIndex, {
                    URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                    Alt: ''
                  })
                } else {
                  storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
                    this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
                  })
                }
                break
              case 'object':
                if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
                  this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
                    if (this.$store.state.user.permission.StaticDomain) {
                      this.Data[type].push({
                        URL: this.$store.state.user.permission.StaticDomain + imgPath,
                        Alt: ''
                      })
                    } else {
                      storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                        this.Data[type].push({
                          URL,
                          Alt: ''
                        })
                      })
                    }
                  })
                }
                break
            }
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
        else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (index) this.Data[type][index] = URL
          else this.$set(this.Data, type, URL)
        })
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false) {
      if (index === false) {
        return false
      }
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.MediaMetaConfig.ImageMeta = this.Data[type][index]
      this.ChooseImageType = type
      this.ChooseImageIndex = index
    },
    SetMeta() {
      this.MediaMetaConfig.ImageMeta = {}
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
    RemoveImage(type = '', index) {
      this.Data[type].splice(index, 1)
    },
    InitLocaleData() {
      if (this.$store.state.user.permission.MutipleLocale) {
        if (!this.Data.LanguageData) {
          this.$set(this.Data, 'LanguageData', {})
        }
        this.$store.state.user.permission.Locales.forEach((locale) => {
          if (!this.Data.LanguageData[locale]) {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Brand: '',
              Content: '<p></p>',
              Unit: '<p></p>',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Gallery: [
                {URL: '', Alt: ''}
              ],
              Carousel: [
                {URL: '', Alt: ''}
              ],
              Cover: '',
              OverViewImages: [
                {URL: '', Alt: ''}
              ],
              HeaderText: '',
              'Variable-FeatureText': [],
              ResponseData: {
                Mobile: {
                  Content: '<p></p>',
                  Unit: '<p></p>',
                },
                Web: {
                  Content: '<p></p>',
                  Unit: '<p></p>',
                }
              }
            })
          }
        })
      }
      this.NowChooseLanguage = this.$store.state.user.permission.MutipleLocale ? this.$store.state.user.permission.DefaultLocale : 'default'
    },
    SynchronizeDefaultLocaleData() {
      const DefaultLocale = this.$store.state.user.permission.DefaultLocale
      if (DefaultLocale !== 'default') {
        this.Data.Name = this.Data.LanguageData[DefaultLocale].Name
        this.Data.OtherName = this.Data.LanguageData[DefaultLocale].OtherName
        this.Data.Brand = this.Data.LanguageData[DefaultLocale].Brand
        this.Data.HeaderText = this.Data.LanguageData[DefaultLocale].HeaderText
        this.Data.Content = this.Data.LanguageData[DefaultLocale].Content
        this.Data.Cover = this.Data.LanguageData[DefaultLocale].Cover
        this.Data.Unit = this.Data.LanguageData[DefaultLocale].Unit
        this.Data.SEO = this.Data.LanguageData[DefaultLocale].SEO
        this.Data.Carousel = this.Data.LanguageData[DefaultLocale].Carousel
        this.Data.Gallery = this.Data.LanguageData[DefaultLocale].Gallery
        this.Data.OverViewImages = this.Data.LanguageData[DefaultLocale].OverViewImages
        this.Data.Variable.FeatureText = this.Data.LanguageData[DefaultLocale]['Variable-FeatureText']
        this.Data.ResponseData = this.Data.LanguageData[DefaultLocale].ResponseData
      }
    },
  }
}
</script>
