import axios from 'axios'

const TinyMCESetting = {
  plugins: [
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'wordcount'
  ],
  toolbar: 'undo redo | blocks | ' +
      'bold italic backcolor | alignleft aligncenter ' +
      'alignright alignjustify | image media | bullist numlist outdent indent | ' +
      'removeformat',
  file_picker_types: 'image',
  file_picker_callback: (callback) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.addEventListener('change', (e) => {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        let data = new FormData()
        data.append('upload', file)
        return axios.post('/api/media/upload', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'xx-csrf-token': localStorage.getItem('XX-CSRF-Token')
          }
        }).then(({ data }) => {
          callback(data.url[0], { title: file.name })
        })
      })
      reader.readAsDataURL(file)
    })

    input.click()
  },
  image_advtab: true,
  language: 'zh_TW',
  language_url: 'https://cdn.marketingless.com/resources/tinymice@5/langs/zh_TW.js'
}

export default TinyMCESetting
