import dayjs from 'dayjs'

export default {
  SeriesNum: '',
  CustomSeriesNum: '',
  Name: '',
  OtherName: '',
  Brand: '',
  Content: '<p></p>',
  Unit: '<p></p>',
  Model: '',
  Categories: [],
  Status: 1,
  Review: 0,
  Sort: 0,
  OnlineTime: dayjs().format('YYYY-MM-DD 00:00:00'),
  OfflineTime: dayjs('2030-12-31 23:59:59').format('YYYY-MM-DD HH:mm:ss'),
  Tags: [],
  ServiceLocation: [],
  SEO: {
    Description: '',
    Keywords: ''
  },
  Gallery: [
    {URL: '', Alt: ''}
  ],
  Cover: '',
  OverViewImages: [
    {URL: '', Alt: ''}
  ],
  HeaderText: '',
  Variable: {
    PropertyType: 'Real',
    SaleMode: 'Default',
    Temperature: 'Common',
    IsHTML: 0,
    Weight: 0,
    NetWeight: 0,
    Length: 0,
    Width: 0,
    Height: 0,
    FeatureText: [],
    GMC_AvailableCountry: [],
    GMC_GoogleProductCategory: '',
    _v: 0
  },
  Offer: {},
  EnableOption: 0,
  Note: '',
  Amount: {
    Cost: 0,
    Suggest: 0,
    Actual: 0,
    Member: 0
  },
  Stock: {
    RealStock: 0,
    SafeStock: 0,
    MaxStock: 0
  },
  OptionField: [{
    Value: [],
    Variable: {},
    Level: 1,
    FieldName: ''
  }],
  Options: [],
  OptionData: [],
  Currency: {
    Allow: [],
    Basic: ''
  },
  Carousel: [
    {URL: '', Alt: ''}
  ],
  LanguageData: {},
  ResponseData: {
    Mobile: {
      Content: '<p></p>',
      Unit: '<p></p>',
    },
    Web: {
      Content: '<p></p>',
      Unit: '<p></p>',
    }
  }
}
